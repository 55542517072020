<template>
	<div class="join">
		<div class="join_header">
			<div class="join_header_top">
				<the-nav></the-nav>
			</div>
			<div class="join_header_bottom w1200">
				<h1>一起为碳中和做点什么</h1>
				<p>join us</p>
				<div class="btn" @click="showBoss">查看职位</div>
			</div>
		</div>
		<div class="join_con w1200">
			<div
				class="join_con_box"
				v-for="(item, index) in list"
				:key="index"
			>
				<div class="join_con_box_left">
					<div class="svg_title">{{ item.name }}</div>
					<svg class="svg" aria-hidden="true">
						<use :xlink:href="`#${item.svg}`"></use>
					</svg>
				</div>
				<div class="join_con_box_right">
					<template v-if="index < 2">
						{{ item.label }}
					</template>
					<template v-else>
						<span
							v-for="(label, labelIndex) in item.label"
							:key="'label' + labelIndex"
							class="jiazhiguan"
							:class="[
								[0, 3, 6].includes(labelIndex) &&
									'jiazhiguan_title'
							]"
						>
							{{ label }}
						</span>
					</template>
				</div>
			</div>
			<div class="btn2" @click="showBoss">
				查看职位（boss直聘）
				<svg class="svg22" aria-hidden="true">
					<use xlink:href="#arrowright"></use>
				</svg>
			</div>
		</div>
		<the-footer></the-footer>
	</div>
</template>

<script>
	export default {
		name: "join",
		data() {
			return {
				list: [
					{
						name: "使命",
						svg: "icon-joinus-a",
						label: "领航储能数智化"
					},
					{
						name: "愿景",
						svg: "icon-joinus-b",
						label: "成为技术领先的储能信息科技企业"
					},
					{
						name: "价值观",
						svg: "icon-joinus-c",
						label: [
							"利他",
							"成就客户是立身之本",
							"极致服务赢得尊重",
							"卓越",
							"不甘平庸，敢于闯无人区",
							"爱惜自己的羽毛，交付高水准结果",
							"坦诚",
							"诚信 信任 平等",
							"营造简单纯粹的组织氛围"
						]
					}
				]
			};
		},
		methods: {
			showBoss() {
				window.open(
					"https://www.zhipin.com/gongsir/7152abf422c9dee91nx_2tu7EFI~.html?ka=company-jobs"
				);
			}
		}
	};
</script>

<style lang="scss">
	.join {
		&_header {
			height: 304px;
			background-image: url("../../assets/imgs/img-news-top-bg.png");
			background-position: center;
			background-size: 3840px 100%;
			&_top {
				height: 80px;
				background: rgba(255, 255, 255, 0);
				box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
			}
			&_bottom {
				padding-top: 32px;
				h1 {
					font-size: 30px;
					font-family: PingFangSC, PingFangSC-Semibold;
					font-weight: 600;
					text-align: left;
					color: #ffffff;
				}
				p {
					margin: 8px 0 32px 0;
					font-size: 14px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					text-align: left;
					color: rgba(255, 255, 255, 0.45);
					text-transform: uppercase;
				}
				.btn {
					width: 144px;
					height: 46px;
					line-height: 44px;
					background: rgba(0, 198, 153, 0.15);
					border: 1px solid rgba(0, 198, 153, 0.45);
					border-radius: 2px;
					font-size: 16px;
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					text-align: center;
					color: #00c699;
					cursor: pointer;
				}
			}
		}
		&_con {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 48px 0 96px 0;
			&_box {
				display: flex;
				width: 999px;
				background-color: #f4f5f7;
				border-radius: 4px;
				padding: 40px 0;
				margin-bottom: 24px;
				&_left {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 280px;
					min-height: 184px;
					border-right: 1px solid #e6e6e6;
					.svg_title {
						font-size: 16px;
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						color: #00c699;
					}
					.svg {
						width: 56px;
						height: 56px;
						margin-top: 8px;
					}
				}
				&_right {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					padding-left: 116px;
					font-size: 24px;
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 600;
					text-align: left;
					color: rgba(0, 0, 0, 0.85);
					> span.jiazhiguan {
						font-size: 14px;
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.45);
					}
					> span.jiazhiguan_title {
						&:first-child {
							margin-top: 0;
						}
						margin-top: 24px;
						font-size: 24px;
						font-weight: 600;
						color: rgba(0, 0, 0, 0.85);
					}
				}
			}
			.btn2 {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 24px;
				padding: 12px 32px;
				background: rgba(0, 198, 153, 0.15);
				border: 1px solid rgba(0, 198, 153, 0.45);
				border-radius: 2px;
				font-size: 16px;
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 400;
				color: #00c699;
				cursor: pointer;
				.svg22 {
					width: 16px;
					height: 16px;
					fill: #00c699;
				}
			}
		}
	}
</style>
